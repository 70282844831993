<template>
  <div style="background-color: white">
    <v-container class="pt-12 font-rubik">

      <span style="font-size: 2.5rem"
            class="font-weight-medium"
      >
        Compra fallida
      </span>
      <br>

    <span>
    La comanda no ha sigut finalitzada. Tampoc t'hem cobrat res. <br>Torna-ho a intentar o contacta amb nosaltres a:
    <a href="mailto:contacte@examenselectivitat.cat">contacte@examenselectivitat.cat</a>
    </span>
      <v-img
        max-width="80%"
        src="https://examenselectivitat.cat:3000/api/images/other/error-image.webp"></v-img>


    </v-container>
  </div>
</template>

<script>
export default {
  name: "CancelledSubscription",
  async mounted() {

  },
  data() {
    return {

    }
  },
  methods: {

  },

}
</script>

<style scoped>

</style>
